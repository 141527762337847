<template>
  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isModalSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-modal-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5
v-if="isUpdateForm"
class="mb-0"
>
          {{ $i18n.t('edit_user') }}
        </h5>
        <h5
v-else
class="mb-0"
>
          {{ $i18n.t('add_user') }}
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(submitForm)"
            @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
              #default="{errors}"
              name="name"
              rules="required"
          >
            <b-form-group
                :label="$i18n.t('Name')"
                label-for="name"
            >
              <b-form-input
                  id="name"
                  v-model="userData.name"
                  autofocus
                  :state="errors.length > 0 ? false:null"
                  trim
                  placeholder="Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
          >
            <b-form-group
                :label="$i18n.t('Email')"
                label-for="email"
            >
              <b-form-input
                  id="email"
                  v-model="userData.email"
                  :state="errors.length > 0 ? false:null"
                  trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- password -->
          <b-form-group
              :label="$i18n.t('Password')"
              label-for="password"
          >
            <validation-provider
                #default="{ errors }"
                name="password"
                vid="password"
                :rules="passwordRules"
            >
              <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                    id="password"
                    v-model="userData.password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="password"
                    placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- confirm password -->
          <b-form-group
              label-for="password-confirm"
              :label="$i18n.t('confirm_password')"
          >
            <validation-provider
                #default="{ errors }"
                name="password-confirm"
                :rules="confirmsPasswordRules"
            >
              <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                    id="password-confirm"
                    v-model="userData.cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="password-confirm"
                    placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- User Suppliers -->
          <validation-provider
              #default="{ errors }"
              name="supplier"
          >
            <b-form-group
                :label="$i18n.t('supplier')"
                label-for="user-supplier"
            >
              <v-select
                  v-model="userData.supplier"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="suppliers"
                  :reduce="val => val"
                  :clearable="false"
                  multiple
                  input-id="supplier"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider
              #default="{ errors }"
              name="role"
              rules="required"
          >
            <b-form-group
                :label="$i18n.t('Role')"
                label-for="user-role"
            >
              <v-select
                  v-model="userData.role"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roles"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="role"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Report Config -->
          <validation-provider
              #default="{ errors }"
              name="last_day"
          >
            <b-form-group
                :label="$i18n.t('last_day')"
                label-for="last_day"
            >
              <v-select
                  v-model="userData.last_day"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="days"
                  label="label"
                  :reduce="val => val.key"
                  clearable
                  input-id="last_day"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- User Language -->
          <validation-provider
              #default="{ errors }"
              name="lang"
              rules="required"
          >
            <b-form-group
                :label="$i18n.t('Lang')"
                label-for="user-lang"
            >
              <v-select
                  v-model="userData.lang"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="available_locales"
                  :reduce="val => val"
                  :clearable="false"
                  input-id="lang"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <b-form-checkbox
              id="2fa"
              v-model="userData.is_enable_2fa"
              name="checkbox-1"
          >
            {{ $i18n.t('is_enable_2fa') }}
          </b-form-checkbox>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
            >
              <span v-if="!isUpdateForm">{{ $i18n.t('Add') }}</span>
              <span v-else>{{ $i18n.t('Update') }}</span>
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
            >
              {{ $i18n.t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>

import {alphaNum, email, required} from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  emits: ['is-modal-sidebar-active', 'refetch-data'],
  directives: {
    Ripple,
  },
  model: {
    prop: 'isModalSidebarActive',
    event: 'update:is-modal-sidebar-active',
  },
  props: {
    isModalSidebarActive: {
      type: Boolean,
      required: true,
    },
    isUpdateForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      roles: [],
      userData: {},
      blankUserData: {
        id: '',
        name: '',
        email: '',
        password: '',
        cPassword: '',
        role: '',
        lang: 'de',
        supplier: [],
        is_enable_2fa: false,
        last_day: null,
      },
      available_locales: [],
      suppliers: [],
      days: [],
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordRules() {
      if (!this.isUpdateForm) {
        return 'required|password'
      }
      return 'password'
    },
    confirmsPasswordRules() {
      if (!this.isUpdateForm) {
        return 'required|confirmed:password'
      }
      return 'confirmed:password'
    }
  },
  mounted() {
    this.resetForm()
    this.getUtils()
  },
  methods: {
    putUser(item){
      this.userData = JSON.parse(JSON.stringify(item))
      this.userData.is_enable_2fa = !!this.userData.is_enable_2fa
      this.userData.role = this.userData.role_id
      this.userData.password = ''
      this.userData.cPassword = ''
    },
    getUtils(){
      this.$http.get('utils/add-edit-user')
          .then(res => {
            this.roles = res.data.data.roles
            this.available_locales = res.data.data.available_locales
            this.suppliers = res.data.data.suppliers
            this.days = this.fixDaysArray(res.data.data.days)
          })
    },
    fixDaysArray(days){
      const arr = []
      days.forEach(day => {
        arr.push({
          key: day,
          label: this.$i18n.t(`days.${day}`)
        })
      })
      return arr
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    resetForm() {
      this.userData = JSON.parse(JSON.stringify(this.blankUserData))
      this.$refs.refFormObserver.reset()
      this.password1FieldType = 'password'
      this.password2FieldType = 'password'
    },
    submitForm() {
      if (!this.isUpdateForm) {
        this.addUser()
      } else {
        this.updateUser()
      }
    },
    addUser() {
      this.$http.post('/users', {
        name: this.userData.name,
        email: this.userData.email,
        password: this.userData.password,
        password_confirmation: this.userData.cPassword,
        role: this.userData.role,
        lang: this.userData.lang,
        supplier: this.userData.supplier,
        is_enable_2fa: this.userData.is_enable_2fa,
        last_day: this.userData.last_day,
      }).then(response => {
        this.successCallback(response)
      }).catch(error => {
        this.errorCallback(error)
      })
    },
    updateUser() {
      const form = {
        name: this.userData.name,
        email: this.userData.email,
        role: this.userData.role,
        lang: this.userData.lang,
        supplier: this.userData.supplier,
        is_enable_2fa: this.userData.is_enable_2fa,
        last_day: this.userData.last_day,
      }
      if (this.userData.password && this.userData.password !== '') {
        this.$set(form, 'password', this.userData.password)
        this.$set(form, 'password_confirmation', this.userData.cPassword)
      }
      this.$http.put(`/users/${this.userData.id}`, form).then(response => {
        this.successCallback(response)
      }).catch(error => {
        this.errorCallback(error)
      })
    },
    successCallback(response) {
      this.$emit('refetch-data')
      this.$emit('update:is-modal-sidebar-active', false)
      this.isUpdateForm = false
      this.resetForm()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$i18n.t('Success'),
          icon: 'EditIcon',
          variant: 'success',
          text: response.data.message
        },
      })
    },
    errorCallback(error) {
      if (error.data && error.data.errors) {
        this.$refs.refFormObserver.setErrors(error.data.errors)
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
