<template>
  <div>
    <form-modal-user
        ref="form"
        :is-modal-sidebar-active.sync="isModalSidebarActive"
        :is-update-form="isUpdateForm"
        @refetch-data="refetchData"
    />
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
              cols="12"
              md="12"
          >
            <div class="d-flex align-items-center justify-content-end mb-2">
              <b-button
                  variant="primary"
                  @click="isUpdateForm = false; isModalSidebarActive = true;"
              >
                <span class="text-nowrap">{{ $i18n.t('add_user') }}</span>
              </b-button>
            </div>
          </b-col>
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $i18n.t('table.Show') }}</label>
            <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $i18n.t('table.entries') }}</label>
          </b-col>

          <b-col
              cols="12"
              md="6"
              class="align-items-center justify-content-end mb-1 mb-md-0"
          >
            <b-form-group
                :label="$i18n.t('table.search.label')"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0 d-flex align-items-center"
            >
              <b-input-group size="sm">
                <b-form-input
                    id="filterInput"
                    v-model="searchWord"
                    type="search"
                    :placeholder="$i18n.t('table.search.placeholder')"
                    @input="handleInput"
                    @keyup.enter="searchTable"

                />
                <b-input-group-append>
                  <b-button
                      :disabled="!searchable"
                      variant="primary"
                      @click="searchTable"
                  >
                    {{ $i18n.t('table.search.btn') }}
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

      </div>
      <loading :show="is_loading">
        <b-table
            hover
            responsive="sm"
            :items="items"
            :fields="fields"
            show-empty
            :empty-text="$i18n.t('table.no_data')"
        >
          <!-- Column: Actions -->
          <template
v-if="can('users','update') || can('users','delete')"
#cell(actions)="data"
>
            <b-dropdown
                variant="link"
                no-caret
            >
              <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
v-if="can('users','update')"
@click="activeEditForm(data.item)"
>
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $i18n.t('Edit') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                  v-if="can('users','delete')"
                  @click="confirmDelete(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $i18n.t('Delete') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                  v-if="isAllowLoginAsUser(data.item)"
                  @click="loginAs(data.item)"
              >
                <feather-icon icon="ArrowRightIcon" />
                <span class="align-middle ml-50">{{ $i18n.t('login_as') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </loading>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="12"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="page"
                :total-rows="totalPages"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formModalUser from './form.vue'

export default {
  components: {
    formModalUser
  },
  data() {
    return {
      totalPages: 1,
      page: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      items: [],
      isModalSidebarActive: false,
      isUpdateForm: false,
      is_loading: false,
      searchWord: '',
    }
  },
  computed: {
    searchable() {
      return !this.is_loading
    },
    fields() {
      return [
        {
          key: 'id',
          label: "#",
          sortable: true
        },
        {
          key: 'name',
          label: this.$i18n.t('Name'),
         sortable: true
       },
       {
         key: 'email',
         label: this.$i18n.t('Email'),
         sortable: true,
       },
        {
          key: 'supplier',
          label: this.$i18n.t('Brands'),
          sortable: false,
          formatter: value => {
            return value ? value.join() : ''
          },
        },
       {
         key: 'role',
         label: this.$i18n.t('Role'),
         sortable: true
       },
       {
         key: 'actions',
         label: this.$i18n.t('table.Actions'),
         sortable: false,
       }
     ]
    }
  },
  watch: {
    perPage(){
      this.getData()
    },
    page(){
      this.getData()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.is_loading = true
      this.$http.get(`users?page=${this.page}&perPage=${this.perPage}&search=${this.searchWord}`)
          .then(res => {
            this.items = []
            const response = res.data.data
            response.data.forEach(item => {
              const data = item
              data.role = item.roles[0].name
              data.role_id = item.roles[0].id
              this.items.push(data)
            })
            this.totalPages = response.total
            this.is_loading = false
          }).catch(error => {
        this.is_loading = false
      })
    },
    refetchData() {
      this.getData()
    },
    searchTable() {
      this.getData()
    },
    handleInput() {
      if (this.searchWord === '') {
        this.searchTable()
      }
    },
    isAllowLoginAsUser(user){
      return this.auth().role === "admin" && user.role !== 'admin'
    },
    loginAs(item){
      this.$http.get(`/login-as/${item.id}`).then(response => {
        const token = response.data.data.access_token
        const {user} = response.data.data
        const accountId = this.auth().id
        this.$ability.update(user.ability)
        this.$store.dispatch('auth/login', {token, user,accountId})
        this.$router.replace('/')
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$i18n.t('Success'),
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
            })
      })
    },
    activeEditForm(item) {
      this.isUpdateForm = true
      this.$refs.form.putUser(item)
      this.isModalSidebarActive = true
    },
    confirmDelete(item) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("messages.not_able_revert"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`/users/${item.id}`).then(response => {
            this.refetchData()
            this.$swal({
              icon: 'success',
              title: `${this.$i18n.t('Deleted')}!`,
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
